import menu from './menu';
import sidebar from './sidebar';
import actionPanel from './action-panel';
import categories from './categories';
import brandSelector from './licences/brand-selector';
import items from './items';
import search from './search';

window.Components = window.Components || {};

window.Components.menu = menu;
window.Components.sidebar = sidebar;
window.Components.actionPanel = actionPanel;
window.Components.categories = categories;
window.Components.dynamic = categories;
window.Components.items = items;
window.Components.search = search;

// Licences
window.Components.brandSelector = brandSelector;
