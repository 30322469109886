export default function (state = {
  focus: false
}) {
  return {
    focus: state.focus,

    init () {
      this.bindEvents()
    },

    onKeyDown (event) {
      const keyCode = event.keyCode
      const ctrlKey = event.ctrlKey

      // Control + k
      if (keyCode === 75 && ctrlKey) {
        this.focus = !this.focus;

        console.log('ctrl + k', this.focus)
      }
    },

    bindEvents () {
      document.addEventListener('keydown', e => this.onKeyDown(e), false);
    }
  }
}
